export default {
    SERVER_IP: "localhost",
    // SERVER_IP: "101.132.157.18",
    // SERVER_IP: "192.168.110.245",
    // SERVER_IP: "www.yigeiot.com",
    DEVICE_TYPE_LIST: [{
            name: "电子天平",
            code: 0,
        },
        {
            name: "光度计",
            code: 1,
        },
        {
            name: "触摸屏三参数",
            code: 3,
        },
        {
            name: "水质仪器",
            code: 4,
        },
        {
            name: "测油仪",
            code: 5,
        },
    ],
    DEVICE_TYPE_NAME_LIST: ["电子天平", "光度计", "", "触摸屏三参数", "水质仪器", "测油仪"],

    ERROR_TYPE_LIST: [{
            name: "设备报警",
            code: 0,
        },
        {
            name: "设备掉线",
            code: 1,
        },
        {
            name: "设备上线",
            code: 2,
        },
        {
            name: "超量程报警",
            code: 3,
        },
    ],
    ACTION_LOG_TYPE: [
        "登录",
        "退出登录",
        "修改密码",
        "修改手机号",
        "修改邮箱",
        "设备操作",
        "添加设备",
        "删除设备",
    ],
};