import {
    createStore
} from "vuex";
import createPersistedState from "vuex-persistedstate"; // 引入持久化插件

export default createStore({
    state: {
        user: null,
        token: null,
        mobile: null,
        password: null,
        videoUrl: null,
        device: null,
        deviceId: null,
        topicList: null,
        pageTitle: '数据看板',


    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setToken(state, token) {
            state.token = token;
        },
        setMobile(state, mobile) {
            state.mobile = mobile;
        },
        setPassword(state, password) {
            state.password = password;
        },
        setVideoUrl(state, videoUrl) {
            state.videoUrl = videoUrl;
        },
        setDevice(state, device) {
            state.device = device;
        },
        setTopicList(state, topicList) {
            state.topicList = topicList;
        },
        setPageTitle(state, pageTitle) {
            state.pageTitle = pageTitle;
        },
        setDeviceId(state, deviceId) {
            state.deviceId = deviceId;
        },
    },
    actions: {},
    modules: {},
    plugins: [
        // createPersistedState({
        // 	storage: window.localStorage
        // })
        createPersistedState(),
    ],
});