import Constant from "../common/constant.js";
export default {
    // BASE_URI: "http://" + Constant.SERVER_IP + ":8686/youke-iot",
    BASE_URI: "https://www.yigeiot.com:8888/youke-iot",

    UPLOAD_FILE: "https://www.yigeiot.com:8888/youke-iot/file/uploadFile", //文件上传

    GET_VERIFY_CODE: "/common/getVerifyCode",
    VERIFY_LOGIN: "/common/verifyLogin",
    PWD_LOGIN: "/common/pwdLogin",
    UPDATE_PWD: "/common/updatePwd",
    UPDATE_PASSWORD: "/common/updatePassword",
    CK_ACCOUNT_SD_CODE: "/common/ckAccountSdCode",
    UPDATE_ACCOUNT: "/common/updateAccount",
    REGISTER: "/common/register",
    WX_BIND_MOBILE: "/common/wxBindMobile",

    UPDATE_USER: "/user/updateUser",

    GET_USER_SET_CONFIG: "/user-set/getUserSetConfig",
    UPDATE_USER_SET_LIST: "/user-set/updateUserSetList",

    BIND_DEVICE: "/device/bindDevice",
    GET_DEVICE_LIST: "/device/getDeviceList",
    GET_DEVICE_LIST2: "/device/getDeviceList2",
    UNBIND_DEVICE: "/device/unbindDevice",
    UPDATE_DEVICE: "/device/updateDevice",
    GET_DEVICE_INFO: "/device/getDeviceInfo",
    UPDATE_DEVICE_EXT: "/device/updateDeviceExt",

    INC_DEVICE_USE_COUNT: "/device-use-count/incDeviceUseCount",

    GET_ACTION_LOG_LIST: "/action-log/getActionLogList",
    ADD_ACTION_LOG: "/action-log/addActionLog",

    GET_ALARM_LIST: "/alarm/getAlarmList",

    ADD_FEEDBACK: "/feedback/addFeedback",
    GET_FEEDBACK_LIST: "/feedback/getFeedbackList",

    GET_INSTRUCTION_LIST: "/instruction/getInstructionList",

    GET_OPENID: "/wx/getOpenid",
    WX_LOGIN: "/wx/wxLogin",

    DECODE_QRCODE: "/tool/decodeQrcode",
    DOWNLOAD_QRCODE: "/tool/downLoadQrcode",

    ADD_DEVICE_DATA: "/device-data/addDeviceData",
    DELETE_DEVICE_DATA: "/device-data/deleteDeviceData",
    GET_DEVICE_DATA_LIST: "/device-data/getDeviceDataList",
    GET_DEVICE_DATA_LIST2: "/device-data/getDeviceDataList2",
    EXPORT_DEVICE_DATA_LIST: "/device-data/exportDeviceDataList",

    ADD_CURVE: "/curve/addCurve",
    GET_CURVE_LIST: "/curve/getCurveList",
    DELETE_CURVE: "/curve/deleteCurve",
    GET_CURVE: "/curve/getCurve",

    GET_DATA1: "/iot-data/getData1",
    GET_DATA2: "/iot-data/getData2",
    GET_DATA3: "/iot-data/getData3",
    GET_DATA4: "/iot-data/getData4",
    GET_DATA5: "/iot-data/getData5",
    GET_DATA6: "/iot-data/getData6",
};