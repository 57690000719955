/**
 *对Date的扩展，将 Date 转化为指定格式的String
 *月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
 *年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 *例子：
 *(new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 *(new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
 */
import Constant from './constant.js'
import Store from '../../store/index.js'
Array.prototype.remove = function (val) {
	var index = this.indexOf(val);
	if (index > -1) {
		this.splice(index, 1);
	}
};


Date.prototype.format = function (fmt) {
	var o = {
		"M+": this.getMonth() + 1,
		//月份
		"d+": this.getDate(),
		//日
		"h+": this.getHours(),
		//小时
		"m+": this.getMinutes(),
		//分
		"s+": this.getSeconds(),
		//秒
		"q+": Math.floor((this.getMonth() + 3) / 3),
		//季度
		"S": this.getMilliseconds() //毫秒

	};
	if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));

	for (var k in o)
		if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" +
			o[k]).substr(
				("" + o[k]).length));

	return fmt;
};


export default {

	//判断字符是否为空的方法
	isEmpty(obj) {
		if (typeof obj == "undefined" || obj == null || obj == "") {
			return true;
		} else {
			return false;
		}
	},
	checkImg(fileType) {
		if (fileType == "image/jpeg" ||
			fileType == "image/png" ||
			fileType == "image/jpg" ||
			fileType == "image/bmp") {
			return true;
		} else {
			return false;
		}

	},
	//avi、wmv、mpeg、mp4、mov、mkv、flv、f4v、m4v、rmvb、rm、3gp、dat、ts、mts、vob
	checkVideo(fileType) {
		if (fileType == "video/avi" ||
			fileType == "video/wmv" ||
			fileType == "video/mpeg" ||
			fileType == "video/mp4" ||
			fileType == "video/mov" ||
			fileType === "video/mkv" ||
			fileType == "video/flv" ||
			fileType == "video/f4v" ||
			fileType == "video/m4v" ||
			fileType == "video/rmvb" ||
			fileType == "video/rm" ||
			fileType == "video/3qp" ||
			fileType == "video/dat" ||
			fileType == "video/ts" ||
			fileType == "video/mts" ||
			fileType == "video/vob"
		) {
			return true;
		} else {
			return false;
		}

	},
	dateFormat(timeStamp, format) {
		if (timeStamp > 0) {
			var date = new Date();
			date.setTime(timeStamp);
			var y = date.getFullYear();
			var m = date.getMonth() + 1;
			m = m < 10 ? "0" + m : m;
			var d = date.getDate();
			d = d < 10 ? "0" + d : d;
			var h = date.getHours();
			h = h < 10 ? "0" + h : h;
			var minute = date.getMinutes();
			var second = date.getSeconds();
			minute = minute < 10 ? "0" + minute : minute;
			second = second < 10 ? "0" + second : second;
			if (format == "HH:mm") {
				return h + ":" + minute;
			} else if (format == "yyyy-MM-dd HH:mm:ss") {
				return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
			} else if (format == "HH:mm:ss") {
				return h + ":" + minute + ":" + second;
			} else if (format == "yyyy-MM-dd") {
				return y + "-" + m + "-" + d;
			}
			return h + ":" + minute;
		} else {
			return "";
		}
	},
	formatWorkTime(workTime) {
		if (workTime == 0) {
			return "0m";
		}
		var hour = parseInt(workTime / 3600);
		var minute = Math.round((workTime - hour * 3600) / 60);
		// var second = parseInt(workTime % 60);
		var hourStr = "";
		if (hour != 0) {
			hourStr = hour + "h";
		}
		var minuteStr = "";
		if (minute != 0) {
			minuteStr = minute + "m";
		}
		return hourStr + minuteStr;
	},

	formatDur(dur) {
		if (dur == 0) {
			return "0m";
		}
		var hour = parseInt(dur / 3600);
		var minute = parseInt((dur - hour * 3600) / 60);
		var hourStr = "";
		if (hour != 0) {
			hourStr = hour + "h";
		}
		var minuteStr = "";
		if (minute != 0) {
			minuteStr = minute + "m";
		}
		if (hour == 0 && minute == 0) {
			return dur + 's';
		}
		return hourStr + minuteStr;
	},
	timeStamp(second_time) {
		var time = parseInt(second_time) + "秒";
		if (parseInt(second_time) > 60) {
			var second = parseInt(second_time) % 60;
			var min = parseInt(second_time / 60);
			time = min + "分" + second + "秒";
			if (min > 60) {
				min = parseInt(second_time / 60) % 60;
				var hour = parseInt(parseInt(second_time / 60) / 60);
				time = hour + "小时" + min + "分" + second + "秒";
				if (hour > 24) {
					hour = parseInt(parseInt(second_time / 60) / 60) % 24;
					var day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24);
					time = day + "天" + hour + "小时" + min + "分" + second + "秒";
				}
			}
		}
		return time;
	},
	formatAge(strBirthday) { //传入形式yyyy-MM-dd
		//strBirthday = util.formatTime(strBirthday);转换成yyyy-MM-dd形式
		var returnAge
		var strBirthdayArr = strBirthday.split('-')
		var birthYear = strBirthdayArr[0]
		var birthMonth = strBirthdayArr[1]
		var birthDay = strBirthdayArr[2]
		var d = new Date()
		var nowYear = d.getFullYear()
		var nowMonth = d.getMonth() + 1
		var nowDay = d.getDate()
		if (nowYear == birthYear) {
			returnAge = 0 //同年 则为0岁
		} else {
			var ageDiff = nowYear - birthYear //年之差
			if (ageDiff > 0) {
				if (nowMonth == birthMonth) {
					var dayDiff = nowDay - birthDay //日之差
					if (dayDiff < 0) {
						returnAge = ageDiff - 1
					} else {
						returnAge = ageDiff
					}
				} else {
					var monthDiff = nowMonth - birthMonth //月之差
					if (monthDiff < 0) {
						returnAge = ageDiff - 1
					} else {
						returnAge = ageDiff
					}
				}
			} else {
				returnAge = -1 //返回-1 表示出生日期输入错误 晚于今天
			}
		}
		return returnAge //返回周岁年龄
	},
	getFileSuffix(fileName) {
		var pos = fileName.lastIndexOf('.'); //'/所在的最后位置'
		return name.substr(pos + 1) //截取文件名称字符串
	},
	getFileName(fileName) {
		var pos = fileName.lastIndexOf('.'); //'/所在的最后位置'
		return fileName.substr(0, pos)
	},
	formatNum0(num, length) {
		for (var len = (num + "").length; len < length; len = num.length) {
			num = "0" + num;
		}
		return num;
	},

	initLineChartOption(title, xData, yData) {
		return {
			title: {
				text: title,
			},
			xAxis: {
				type: 'category',
				data: xData
			},
			yAxis: {
				type: 'value'
			},
			grid: {
				left: '1%',
				right: '1%',
				bottom: '0%',
				containLabel: true
			},
			series: [{
				data: yData,
				type: 'line',
				smooth: true
			}],
			dataZoom: [{
				type: "inside"
			}],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow'
				}
			},
		};
	},

	initStepLineChartOption(title, xData, yData) {
		return {
			title: {
				text: title,
			},
			xAxis: {
				type: 'category',
				data: xData,
				axisLine: {
					show: false
				}
			},
			yAxis: {
				type: 'value',
				min: -0.3,
				max: 1,
				splitNumber: 1,
				show: false
			},
			grid: {
				left: '1%',
				right: '1%',
				bottom: '0%',
				containLabel: true
			},
			series: [{
				data: yData,
				type: 'line',
				step: 'start',
			}],

		};
	},
	initPieOption(title, labletTitle, data, color, unit) {
		var option = {
			title: {
				text: title,
			},
			tooltip: {
				trigger: 'item'
			},
			legend: {
				type: 'scroll',
				orient: 'vertical',
				right: 0,
				top: 30,
				bottom: 0
			},
			bottom: 0,
			padding: 0,
			margin: 0,
			series: [{
				name: labletTitle,
				type: 'pie',
				avoidLabelOverlap: false,
				itemStyle: {
					borderRadius: 10,
					borderColor: '#fff',
					borderWidth: 2
				},
				label: {
					show: false,
					position: 'center'
				},
				emphasis: {
					label: {
						show: true,
						fontSize: '40',
						fontWeight: 'bold'
					}
				},
				labelLine: {
					show: false
				},
				data: data
			}]
		};
		if (color) {
			option.color = color;
		}
		if (unit) {
			option.label = {
				alignTo: 'edge',
				formatter: "{name|{b}}\n{time|{c} " + unit + "}",
				minMargin: 5,
				edgeDistance: 10,
				lineHeight: 15,
				rich: {
					time: {
						fontSize: 10,
						color: '#999'
					}
				}
			};
		}
		return option;
	},

	initRingOption(title, labelTitle, data, color) {
		var option = {
			title: {
				text: title,
			},
			tooltip: {
				trigger: 'item'
			},
			legend: {
				type: 'scroll',
				orient: 'vertical',
				right: 0,
				top: 30,
				bottom: 0
			},
			bottom: 0,
			padding: 0,
			margin: 0,
			series: [{
				name: labelTitle,
				type: 'pie',
				radius: ['40%', '70%'],
				avoidLabelOverlap: false,
				itemStyle: {
					borderRadius: 10,
					borderColor: '#fff',
					borderWidth: 2
				},
				label: {
					show: false,
					position: 'center'
				},
				emphasis: {
					label: {
						show: true,
						fontSize: '40',
						fontWeight: 'bold'
					}
				},
				labelLine: {
					show: false
				},
				data: data
			}]
		};
		if (color) {
			option.color = color;
		}
		return option;


	},

	initPieOption1(title, data, itemTitle, unit) {
		return {
			title: {
				text: title,
				left: 'center'
			},
			tooltip: {
				trigger: 'item',
				formatter: '{a} <br/>{b} : {c} ({d}%)'
			},
			label: {
				alignTo: 'edge',
				formatter: "{name|{b}}\n{time|{c} " + unit + "}",
				minMargin: 5,
				edgeDistance: 10,
				lineHeight: 15,
				rich: {
					time: {
						fontSize: 10,
						color: '#999'
					}
				}
			},
			labelLine: {
				length: 15,
				length2: 0,
				maxSurfaceAngle: 80
			},
			legend: {
				type: 'scroll',
				orient: 'vertical',
				right: 10,
				top: 20,
				bottom: 20,
			},
			series: [{
				name: itemTitle,
				type: 'pie',
				radius: '55%',
				center: ['40%', '50%'],
				data: data,
				emphasis: {
					itemStyle: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					}
				}
			}]
		};
	},


	initBarOption(title, xData, yData, color) {
		return {
			title: {
				text: title,
			},
			color: color ? color : ['#A234F8'],
			xAxis: {
				type: 'category',
				data: xData,
			},
			yAxis: {
				type: 'value',
			},
			grid: {
				left: '1%',
				right: '1%',
				bottom: '0%',
				containLabel: true
			},
			series: [{
				realtimeSort: true,
				data: yData,
				type: 'bar',
				label: {
					show: true,
					position: 'inside'
				},
			}],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow'
				}
			},
			dataZoom: [{
				type: 'inside'
			}],
		};
	},


	initMultBarOption(title, xData, series, color) {
		return {
			title: {
				text: title,
			},
			xAxis: {
				type: 'category',
				data: xData
			},
			yAxis: {
				type: 'value',
			},
			legend: {},
			grid: {
				left: '1%',
				right: '1%',
				bottom: '0%',
				containLabel: true
			},
			series: series,
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow'
				}
			},
		};
	},



	initGaugeOption1() {

	},
	formatUserType(userType) {
		for (var item of Constant.USER_TYPE) {
			if (item.value == userType) return item.name;
		}
	},
	prefixInteger(num, m) {
		return (Array(m).join(0) + num).slice(-m);
	},

	getParam(that, key, storeMethod) {
		var value = that.$route.params[key];
		if (value) {
			that.$store.commit(storeMethod, value);
			console.log('====更新值了====')
		} else {
			value = that.$store.state[key];
		}
		return value;
	},

	download(url, fileName) {
		let x = new XMLHttpRequest();
		x.open("GET", url, true);
		x.responseType = 'blob';
		x.onload = function (e) {
			let url = window.URL.createObjectURL(x.response)
			let a = document.createElement('a');
			a.href = url
			a.download = fileName
			a.click()
		}
		x.send();
	},
	getObjFromAry(array, key, value) {
		for (var item of array) {
			if (item[key] == value) {
				return item;
			}
		}
		return null;
	},
	str_pad(zero, hex) {
		var tmp = zero.length - hex.length;
		return zero.substr(0, tmp) + hex;
	},
	str2Bytes(str) {
		var pos = 0;
		var len = str.length;
		if (len % 2 != 0) {
			return null;
		}
		len /= 2;
		var hexA = new Array();
		for (var i = 0; i < len; i++) {
			var s = str.substr(pos, 2);
			var v = parseInt(s, 16);
			hexA.push(v);
			pos += 2;
		}
		return hexA;
	},
	isInteger(obj) {
		return Math.round(obj) === obj //是整数，则返回true，否则返回false
	},
	getUrlParams(url) {
		let requestParams = {};
		if (url.indexOf('?') !== -1) {
			let str = url.substr(url.indexOf('?') + 1); //截取?后面的内容作为字符串
			console.log(str, '?后面的内容');
			let strs = str.split('&'); //将字符串内容以&分隔为一个数组
			console.log(strs, '以&切割的数组');
			for (let i = 0; i < strs.length; i++) {
				requestParams[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
				// 将数组元素中'='左边的内容作为对象的属性名，'='右边的内容作为对象对应属性的属性值
			}
		}
		return requestParams;
	},
	toHexString(uint8Array) {
		return Array.prototype.map
			.call(uint8Array, (x) => ('00' + x.toString(16)).slice(-2))
			.join('');
	},
	parseJson(str) {
		try {
			return JSON.parse(str)
		} catch (error) {
			console.log(error)
		}
		return str;
	},
	bytes2ASCII(bytes) {
		var name = '';
		for (var byt of bytes) {
			if (byt != 0) {
				name += String.fromCharCode(byt);
			} else {
				return name;
			}
		}
		return name;

	}



}
