import axios from 'axios'
import qs from 'qs'
import api from "./api";
import store from '../../store/index.js'

import {
    ElMessage
} from 'element-plus'


axios.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error)
})

axios.interceptors.response.use(response => {
    return response
}, error => {
    return Promise.resolve(error.response)
})


export default {
    get(reqUrl, params, successCallBack) {
        let paramsObj = {};
        for (var key in params) {
            if (!(params[key] === null || params[key] === '' || params[key] === undefined)) {
                paramsObj[key] = params[key];
            }
        }
        axios({
                method: "get",
                baseURL: api.BASE_URI,
                data: qs.stringify(paramsObj),
                url: reqUrl + "?token=XXXXXXXXXXXXXXX",
                timeout: 10000,
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
                }
            })
            .then(response => {
                successCallBack(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
                // ElMessage.error("网络异常");
            });
    },
    post(reqUrl, params, successCallBack, errorCallBack) {
        let paramsObj = {};
        for (var key in params) {
            if (!(params[key] === null || params[key] === '')) {
                paramsObj[key] = params[key];
            }
        }
        console.log(JSON.stringify(paramsObj));
        axios({
                method: "post",
                baseURL: api.BASE_URI,
                data: qs.stringify(paramsObj, {
                    arrayFormat: 'repeat'
                }),
                url: reqUrl + "?token=" + store.state.token,
                timeout: 10000,
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
                }
            })
            .then(response => {
                console.log(response.data);
                if (response.data.resultCode == 0) {
                    successCallBack(response.data);
                } else {
                    console.log("==reqUrl==" + reqUrl)
                    if (reqUrl == api.WX_LOGIN) {
                        if (errorCallBack != null && errorCallBack != undefined) errorCallBack(response.data);
                    } else {
                        ElMessage.error(response.data.resultMsg);
                        if (errorCallBack != null && errorCallBack != undefined) errorCallBack(response.data);
                    }

                }
            })
            .catch(error => {
                console.log(error);
                // ElMessage.error("网络异常");
            });
    },


}