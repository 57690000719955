import {
    createRouter,
    createWebHashHistory,
    createWebHistory,
} from "vue-router";

const routes = [{
        path: "/login",
        component: () => import("../views/login.vue"),
    },
    // {
    //   path: "/map",
    //   component: () => import("../views/map.vue"),
    // },
    {
        path: "/",
        component: () => import("../views/main.vue"),
        meta: {
            requireAuth: true,
        },
        children: [{
                path: "/",
                component: () => import("../views/home.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/map",
                component: () => import("../views/map.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/device",
                name: "device",
                component: () => import("../views/device.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/operate0",
                name: "operate0",
                component: () => import("../views/operate0.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/operate1",
                name: "operate1",
                component: () => import("../views/operate1.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/operate3",
                name: "operate3",
                component: () => import("../views/operate3.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/operate4",
                name: "operate4",
                component: () => import("../views/operate4.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/operate5",
                name: "operate5",
                component: () => import("../views/operate5.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/add-curve",
                name: "add-curve",
                component: () => import("../views/add-curve.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/curve-list",
                name: "curve-list",
                component: () => import("../views/curve-list.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/user-info",
                component: () => import("../views/user-info.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/update-pwd",
                component: () => import("../views/update-pwd.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/action-log",
                component: () => import("../views/action-log.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/feedback",
                component: () => import("../views/feedback.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/about",
                component: () => import("../views/about.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/ins-video",
                component: () => import("../views/ins-video.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/video-preview",
                name: "video-preview",
                component: () => import("../views/video-preview.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/ins-pdf",
                name: "ins-pdf",
                component: () => import("../views/ins-pdf.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/alarm",
                name: "alarm",
                component: () => import("../views/alarm.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/device-info",
                name: "device-info",
                component: () => import("../views/device-info.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/setting",
                name: "setting",
                component: () => import("../views/setting.vue"),
                meta: {
                    requireAuth: true,
                },
            },
            {
                path: "/device-data",
                name: "device-data",
                component: () => import("../views/device-data.vue"),
                meta: {
                    requireAuth: true,
                },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

export default router;