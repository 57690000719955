import {
    createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "animate.css";
import store from "./store";
import http from "./js/http/http";
import api from "./js/http/api";
import CommonUtils from "./js/common/common-utils";
import VueClipboard from 'vue-clipboard2'

createApp(App)
    .use(store)
    .use(router)
    .use(VueClipboard)
    .use(ElementPlus, {
        size: "mini",
        zIndex: 3000,
    })
    .mount("#app");

router.beforeEach((to, from, next) => {
    // 从当前 URL 中获取查询参数
    const queryString = window.location.search;
    console.log('aaaa Parameter:', JSON.stringify(queryString));
    var params = CommonUtils.getUrlParams(queryString);
    console.log("==params==" + JSON.stringify(params));
    if (to.path == "/" && !CommonUtils.isEmpty(params.code)) {
        http.post(
            api.WX_LOGIN, {
                code: params.code,
            }, (data) => {
                if (data.data.isLogin) {
                    store.commit("setUser", data.data.user);
                    next();
                } else {
                    next({
                        path: "/login", // 未登录则跳转至login页面
                        redirect: "/login", // 登陆成功后回到当前页面，这里传值给login页面，to.fullPath为当前点击的页面
                        query: {
                            wxId: data.data.wxId
                        }
                    });
                }
            }, (error) => {
                next();
            }
        );
    } else {
        if (to.matched.some((res) => res.meta.requireAuth)) {
            // 验证是否需要登陆
            // var id = window.sessionStorage.getItem("user");
            var id = store.state.user;
            console.log(JSON.stringify(id));
            if (id) {
                // 查询本地存储信息是否已经登陆
                next();
            } else {
                next({
                    path: "/login", // 未登录则跳转至login页面
                    redirect: "/login", // 登陆成功后回到当前页面，这里传值给login页面，to.fullPath为当前点击的页面
                });
            }
        } else {
            next();
        }
    }
});