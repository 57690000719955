<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import MqttUtils from "./js/common/mqtt-utils";
export default {
  created() {
    MqttUtils.handleCon();
    MqttUtils.init();
  },
  beforeUnmount() {
    console.log("=========");
  },
};
</script>

<style>
@import url("assets/css/common.css");

body,
html {
  margin: 0px;
  padding: 0px;
  color: #11214d;
  min-width: 1450px;
  overflow: scroll;
}

* {
  margin: 0px;
  padding: 0px;
}

::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.box-item {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  height: auto;
  /* width: auto; */
}
</style>
