// import mqtt from "mqtt";
import * as mqtt from 'mqtt/dist/mqtt.min';
import store from "../../store/index";
import Emitter from "../../mitt/event";
export default {
  MQTT_SERVER: "wss://www.yigeiot.com:8084/mqtt", //ws的端口是8083  TCP端口是1883
  MQTT_CONFIG: {
    username: "youke",
    password: "youke123",
    clientId: "WEB_" + new Date().getTime(),
    keepAlive: 60,
    cleanSession: true,
    clear: true,
  },
  mTopicList: [],
  mClient: null,
  mConState: 0, //0 未连接 1:正在连接 2:连接成功

  init() {
    if (store.state.topicList) {
      this.mTopicList = store.state.topicList;
    }
  },
  subAllTopic() {
    for (var item of this.mTopicList) {
      this.subTopic(item);
    }
  },

  handleCon() {
    if (this.mConState != 0) return;
    console.log("==handleCon==");
    this.mClient = mqtt.connect(this.MQTT_SERVER, this.MQTT_CONFIG);
    // mqtt连接
    this.mClient.on("connect", (e) => {
      console.log(e, "mqtt连接成功！");
      this.mConState = 2;
      this.subAllTopic();
    });

    // 消息处理
    this.mClient.on("message", (topic, message) => {
      console.log("收到消息", topic, message);
      if (topic.indexOf("$SYS/brokers/") == 0) {
        //在线离线消息
        Emitter.emit("MQTT-ONLINE", topic);
        Emitter.emit("MQTT-MAIN-ONLINE", topic);
      } else {
        var msg = {
          data: message,
          topic: topic
        };
        Emitter.emit("MQTT-MSG", msg);
      }

      // var uint8_msg = new Uint8Array(message);
      // try {
      //   var msg = JSON.parse(String.fromCharCode.apply(null, uint8_msg));
      //   console.log("收到消息", topic, msg);
      //   if (topic.indexOf("$SYS/brokers/") == 0) {
      //     //在线离线消息
      //     Emitter.emit("MQTT-ONLINE", topic);
      //     Emitter.emit("MQTT-MAIN-ONLINE", topic);
      //   } else {
      //     msg.topic = topic;
      //     Emitter.emit("MQTT-MSG", msg);
      //   }
      // } catch (err) {
      //   //在此处理错误
      // }





    });

    // 断线重连
    this.mClient.on("reconnect", (error) => {
      console.log("正在重连：", new Date().getTime(), error);
      this.mConState = 1;
    });

    // 连接失败
    this.mClient.on("error", (err) => {
      console.log("mqtt连接失败！{}", err);
      this.mClient.end();
      this.mConState = 0;
    });
  },

  /**
   * 【通用】发布话题
   *
   */

  pubTopic(topic, msg) {
    if (!this.mClient) return;
    // console.log("topic=>" + topic)
    this.mClient.publish(topic, msg, 1, (err) => {
      if (!err) {
        // console.log("发布成功！");
      } else {
        console.log("发布失败！", err);
      }
    });
  },

  /**
   * 【通用】订阅话题
   */
  subTopic(topic) {
    if (!this.mClient) return;
    this.mClient.subscribe(topic, 1, (err) => {
      if (!err) {
        console.log("订阅成功！=>" + topic);
      } else {
        console.log("订阅失败！", err);
      }
    });
  },
  /**
   * 【通用】订阅话题
   */
  addTopic(topic) {
    if (this.mTopicList.indexOf(topic) == -1) {
      if (this.mConState == 2) {
        this.subTopic(topic);
      }
      this.mTopicList.push(topic);
      store.commit("setTopicList", this.mTopicList);
    }
  },

  closeCon() {
    this.mConState = 0;
    this.mClient.end();
  },
};
